import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = ({imageName, className, alt = 'image'}) => {
  const data = useStaticQuery(graphql`
    query ProcessPageQuery {
        allFile(filter: {relativePath: {}, relativeDirectory: {}}) {
          edges {
            node {
              base
              childImageSharp {
                fluid {
                  aspectRatio
                  base64
                  src
                  srcSet
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
  `)

  return (
    <>
      {data.allFile.edges.map(({node}, idx) => (
        node.base === imageName &&
        <Img key={idx} fluid={node.childImageSharp.fluid} className={className} alt={alt}/>
      ) )}
    </>
  )
}

export default Image
